.c-software-container .c-container[data-widget-title="DownloadsContainer"] {
    max-width: 100% !important;
}

.c-download-container .c-download-components {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.c-download-container .survey-container {
    max-height: 125px !important;
    margin-top: -45px !important;
    padding-right: 20px;
}
.c-richtext-component p {
    margin-bottom: 0.2rem;
}
