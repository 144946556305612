  button.is-small {
  font-size: var(--ads-cta-button-font-size-small, 16px);
  height: var(--ads-cta-button-height-small, 29px);
  padding: var(--ads-cta-button-padding-small, 0 20px);
  border-radius: 40px;
  z-index: 0 !important;
  }
  button.is-primary {
  background-color: var(--ads-cta-button-background-color-primary, #ffc700);
  border: none;
  box-shadow: inset 0px 0px 0px var(--ads-cta-button-border-width, 1px) var(--ads-cta-button-border-color-primary, #ffc700);
  color: var(--ads-cta-button-color-primary, #000);
  z-index: 0 !important;
  }
  button.is-secondary {
  background-color: var(--ads-cta-button-background-color-secondary, transparent);
  border: none;
  box-shadow: inset 0px 0px 0px var(--ads-cta-button-border-width, 1px) var(--ads-cta-button-border-color-secondary, rgba(111, 119, 124, 0.85));
  color: var(--ads-cta-button-color-secondary, #000);
  z-index: 0 !important;
  }
  .dev-c-editions-table {
  --ads-table-font-size: 16px;
  --ads-table-heading-background-color-greyscale: var(--arm-light-grey);
  --ads-table-heading-border-color-sticky: var(--arm-web-safe-dark-grey);
  --ads-table-heading-color: var(--arm-black);
  --ads-table-row-background-color-striped: var(--arm-light-grey-alt);
  --ads-table-border-color: var(--arm-web-safe-dark-grey);
  border: 1px solid #e5eceb !important; 
   border: 1px solid var(--ads-table-border-color,#e5eceb) !important;
    border-radius: 4px;
    height: auto;
    margin: auto;
    overflow: auto;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  html[theme='dark'] .dev-c-editions-table {
  --ads-table-background-color: var(--arm-color-overlay-dark);
  --ads-table-column-background-color-sticky: var(--arm-color-overlay-dark);
  --ads-table-heading-background-color-greyscale: var(--arm-color-overlay-dark);
  --ads-table-heading-border-color-sticky: var(--black);
  --ads-table-heading-color: var(--white);
  --ads-table-row-background-color-striped: var(--arm-black);
  --ads-table-border-color: var(--black);
  }
  .dev-c-editions-table thead th,
  .dev-c-editions-table tbody th {
  font-weight: 400;
  }
  .dev-c-editions-table tbody th {
  border-right: 1px solid var(--ads-table-border-color);
  border-top: 1px solid var(--ads-table-border-color);
  }
  .dev-c-editions-table th,
  .dev-c-editions-table td {
  padding: 16px;
  }
  .dev-c-editions-table__button {
  z-index: 0;
  }
  button.is-primary:hover {
  box-shadow: inset 0px 0px 0px var(--ads-cta-button-border-width-hover, 1px) var(--ads-cta-button-border-color-primary-hover, #333e48);
  }
