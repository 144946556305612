.videoContainer
{
    width: 100%;
    min-height: 400px;
}
.feedback_container
{
    padding: 10px 10px 0 0 !important;
}
.videoComponentInternal
{
   width: 70%;
   margin-left: 15%;
}