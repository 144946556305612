#dev-tools-software-grid {
  grid-template-columns: 1fr 1fr;
}

@media (max-width: 991px) {
  #dev-tools-software-grid {
    grid-template-columns: 1fr;
  }
}

#dev-tools-tab-heading {
  border-bottom: 1px solid #7d868c;
}