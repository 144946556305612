.dev-c-horizontalTab{
  min-height: 360px;
}
#horizontal-tabs {
  --ads-horizontal-tabs-tab-container-position: sticky;
  --ads-horizontal-tabs-tab-container-top: 50px;
}

@media screen and (min-width: 1200px) {
  #horizontal-tabs {
    --ads-horizontal-tabs-tab-container-top: 79px;
  }
}