.c-table.is-greyscale thead th{
    font-weight: initial!important;
}
.c-table.is-sticky tbody th{
    text-align: left;
    font-weight: 400!important;
}
.c-table.is-greyscale .arm-pointer{
    cursor: pointer;
}
html[theme=dark] .dowload-package:hover .fa-download
{
    color: #ff6b00!important
}
html[theme=default] .dowload-package:hover .fa-download
{
    color: #c75300!important
}
.c-table.is-sticky{
    min-width: 480px!important;
}

.sort-icon-postion-right {
    margin-top: 11px;
    float: right;
}

.sort-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
}
  
  /* Optional media query for small screens */
@media (max-width: 600px) {
    .sort-header {
      flex-direction: row;
    }
    .sort-icon {
      font-size: 1.2em;
    }
  }