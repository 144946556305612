  .dev-c-devsummit-card {
      --ads-card-background-color: var(--arm-light-grey-alt-2);
      --ads-card-background-color-hover: var(--arm-light-grey-alt-2);
      grid-column: unset;
      grid-row: unset;
    }

    html[theme='dark'] .dev-c-devsummit-card {
      --ads-card-background-color: var(--arm-color-surface);
      --ads-card-background-color-hover: var(--arm-color-surface);
    }

    @media (min-width: 768px) {
      .dev-c-devsummit-card {
        grid-column: 1 / span 3;
        grid-row: 1;
      }

      .dev-c-devsummit-card .dev-c-devsummit-card__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1;
      }

      .dev-c-devsummit-card__heading {
        grid-area: 1 / 1 / 2 / 3;
      }

      .dev-c-devsummit-card__image {
        grid-area: 1 / 3 / 5 / 4;
      }

      .dev-c-devsummit-card__paragraph {
        grid-area: 2 / 1 / 4 / 3;
      }
    }

    