.div-aditional-resouce-external-link
{
    padding-left: 18px !important;
    
}
.div-aditional-resouce-external-link p
{
    display:list-item; 
    list-style: disc outside;
    margin :auto auto auto .6rem
}

.div-aditional-resouce-external-link a
{
    word-break: break-all;
}