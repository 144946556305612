.dev-c-globalnav{
  min-height: 50px;
  background-color: #272F38;
}
@media(min-width: 1200px) {
  .dev-c-globalnav{
    min-height: 105px;
  }
}
@media (max-width: 576px){
  ads-masthead>ads-breadcrumbs {
    visibility: visible!important;
  }
}