.dev-c-card--support {
  --ads-card-border-radius: 10px;
  --ads-card-border-width: 1px;
  --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);
}

html[theme='dark'] .dev-c-card--support {
  --ads-card-background-color: var(--arm-color-base);
  --ads-card-background-color-hover: var(--arm-color-base);
}
.dev-c-card--support img {
    display: block;
}
