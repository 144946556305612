 
.dev-c-card--works-well {
  --ads-card-box-shadow-hover: 0 1px 3px 3px rgba(0,0,0,0.25);
  --ads-card-border-color: var(--black);
  --ads-card-border-color-hover: var(--arm-orange);
  --ads-card-border-width: 1px;
  }
 .dev-c-card--works-well ads-card-content > div {
  transform: translateX(0);
  transition: transform .25s ease-in;
 }
 
 .dev-c-card--works-well:focus ads-card-content > div,
 .dev-c-card--works-well:hover ads-card-content > div {
 transform: translateX(3px);
 }
 .dev-c-card--works-well h4 {
  color: var(--arm-blue);
  transition: color .25s ease-in;
 }
 
 .dev-c-card--works-well:focus h4,
 .dev-c-card--works-well:hover h4 {
 color: var(--arm-orange);
 }
 
 html[theme='dark'] .dev-c-card--works-well {
  --ads-card-background-color: var(--arm-color-base);
  --ads-card-background-color-hover: var(--arm-color-base);
 }
 
 .dev-c-card--works-well ads-card-content::before {
 background-color: var(--arm-light-blue);
 content: '';
 height: calc(100% + 2px);
 left: -1px;
 position: absolute;
 top: -1px;
 transition: background-color;
 transition-duration: 0.3s;
 transition-timing-function: ease-in-out;
 width: 12px;
 }
 .dev-c-card--works-well:hover ads-card-content::before {
 background-color: var(--arm-orange);
 }
