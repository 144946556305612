.dev-c-card.dev-c-card--home{
  --ads-card-background-color: var(--white, #fff);
  --ads-card-background-color-hover: var(--white, #fff);
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-hover: var(--arm-web-safe-orange, #c75300);
  --ads-card-border-radius: 10px;
  --ads-card-border-width: 1px;
  --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);
}
html[theme="dark"] .dev-c-card.dev-c-card--home {
  --ads-card-background-color: var(--arm-color-surface, #27292d);
  --ads-card-background-color-hover: var(--arm-color-surface, #27292d);
  --ads-card-border-color: var(--black, #000);
}