.dev-c-card--use-case {
    --ads-card-background-color: var(--white);
    --ads-card-background-color-hover: var(--white);
    --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);
    --ads-card-border-color: var(--arm-light-grey);
    --ads-card-border-color-hover: var(--arm-orange);
    --ads-card-border-radius: 10px;
    --ads-card-border-width: 1px;
  }

  html[theme='dark'] .dev-c-card--use-case {
    --ads-card-background-color: var(--arm-color-base);
    --ads-card-background-color-hover: var(--arm-color-base);
    --ads-card-border-color: var(--black);
  }
                
