html:not([theme='dark']) .dev-c-community-area-tag {
    --ads-tag-background-color: transparent;
    --ads-tag-border-color: var(--arm-dark-grey-alt);
    --ads-tag-border-color-hover: var(--arm-dark-grey-alt);
    --ads-tag-color: var(--arm-dark-grey-alt);
    --ads-tag-color--hover: var(--arm-dark-grey-alt);
  }

  .dev-c-card--community-preview {
    --ads-card-border-color: var(--arm-light-grey);
    --ads-card-border-color-hover: var(--arm-web-safe-orange);
    --ads-card-border-radius: 10px;
    --ads-card-border-width: 1px;
  }

  html[theme='dark'] .dev-c-card--community-preview {
    --ads-card-background-color: #27292d;
    --ads-card-background-color-hover: #27292d;
    --ads-card-border-color: var(--black);
    --ads-card-border-color-hover: var(--arm-orange);
  }
  .container{
    grid-auto-columns: max-content;
  }
  .container-auto{
    grid-auto-columns: max-content auto;
  }

  .container-max-content-auto{
    grid-auto-columns: max-content max-content auto;
  }

  .author-container{
  grid-auto-columns: auto 1fr;
  }
  
  @media (min-width: 992px){
    .community-section-FS .community-forum{
     padding-left: 0 !important;
  }
  }
  