.fixedPolicyBox {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
}

.fixedPolicyBox .c-button:active {
  font-size: 16px;
  padding:0 10px;
  margin:0
}
