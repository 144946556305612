#homeHeader {background-image: url('../../../assets/img/header-bg-light/Header375x538Option1Light.svg'); }
@media (min-width: 576px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header576x538Option1Light.svg'); }}
@media (min-width: 768px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header769x395Light.svg'); }}
@media (min-width: 992px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header992x395Light.svg'); }}
@media (min-width: 1200px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header1200x395Light.svg'); }}
@media (min-width: 1400px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header1400x536Light.svg'); }}
@media (min-width: 1920px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header1920x536LightOption2.svg'); }}
@media (min-width: 3000px) { #homeHeader {background-image: url('../../../assets/img/header-bg-light/Header3000x692Light.svg'); }}
html[theme='dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header375x538Option1Dark.svg'); }
@media (min-width: 576px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header576x538Option1Dark.svg'); }}
@media (min-width: 768px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header769x395Dark.svg'); }}
@media (min-width: 992px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header992x395Dark.svg'); }}
@media (min-width: 1200px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header1200x395Dark.svg'); }}
@media (min-width: 1400px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header1400x536Dark.svg'); }}
@media (min-width: 1920px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header1920x536DarkOption2.svg'); }}
@media (min-width: 3000px) {html[theme = 'dark'] #homeHeader {background-image: url('../../../assets/img/header-bg-dark/Header3000x692Dark.svg'); }}