.dev-c-card--tools-software img {
  display: block;
}

.dev-c-card--tools-software {
  --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);
  --ads-card-border-width: 1px;
}

html[theme='dark'] .dev-c-card--tools-software {
  --ads-card-background-color: var(--arm-color-base);
  --ads-card-background-color-hover: var(--arm-color-base);
}