.c-app {
  min-height: 100vh;
}

/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

.content {
  margin-bottom: 14px;
  text-align: left;
}
/***********/
/* GENERIC */
/***********/

body {
  --ads-font-size-large: 16px;
}

p {
  font-size: 1rem;
}

/**************/
/* COMPONENTS */
/**************/

.dev-c-skip-navigation a {
  position: absolute;
  top: -1000em;
}

.dev-c-skip-navigation a:focus {
  position: relative;
  top: auto;
}

/* Global Navigation */

ads-global-navigation {
  --ads-global-navigation-action-overlay-border-color: var(--black);
}

.dev-c-mobile-slotted-flyout {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
}

.dev-c-navigation-wrapper {
  min-height: 50px;
}

@media (min-width: 1200px) {
  .dev-c-navigation-wrapper {
    height: 105px;
  }
}

.c-flyout-card::after {
  display: none !important;
}

.c-flyout-card:focus-within .c-flyout-card__title,
.c-flyout-card:hover .c-flyout-card__title {
  color: var(--arm-orange) !important;
}

/* Home */

.dev-c-home-header {
  background-color: #fff;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 375px 538px;
  height: 538px;
}

html[theme="dark"] .dev-c-home-header {
  background-color: transparent;
}

@media (min-width: 576px) {
  .dev-c-home-header {
    background-position: center center;
    background-size: 576px 538px;
  }
}

@media (min-width: 768px) {
  .dev-c-home-header {
    background-size: 768px 395px;
    height: 395px;
  }

  .dev-c-home-header__container {
    margin-top: 85px;
  }
}

@media (min-width: 992px) {
  .dev-c-home-header {
    background-size: 992px 395px;
  }
}

@media (min-width: 1200px) {
  .dev-c-home-header {
    background-size: 1200px 395px;
  }
}

@media (min-width: 1400px) {
  .dev-c-home-header {
    background-size: 1400px 536px;
    height: 536px;
  }

  .dev-c-home-header__container {
    margin-top: 157px;
  }
}

@media (min-width: 1920px) {
  .dev-c-home-header {
    background-size: 1920px 536px;
  }
}

@media (min-width: 3000px) {
  .dev-c-home-header {
    background-size: 3000px 692px;
    height: 692px;
  }

  .dev-c-home-header__container {
    margin-top: 236px;
  }
}

.dev-c-home-cta-link {
  --ads-cta-link-font-size: 2rem;
}

.dev-c-home-search {
  --ads-search-background-color: rgba(229, 236, 235, 0.25);
  --ads-search-height: 66px;
}

@media (max-width: 1199px) {
  .dev-c-home-search__tags > .c-tag:not(:nth-child(-n + 3)) {
    display: none;
  }
}

html[theme="dark"] .dev-c-home-search {
  --ads-search-background-color: rgba(256, 256, 256, 0.05);
  --ads-search-border-bottom-color: #a3a8ae;
}

.dev-c-home-section--start-developing {
  background-color: rgb(229, 236, 235, 40%);
}

/* Masthead */

.dev-c-masthead {
  min-height: 90px;
}

@media (min-width: 768px) {
  .dev-c-masthead {
    height: 105px;
  }
}

/* Horizontal Tabs */

.dev-c-tab-heading {
  border-style: solid;
  border-width: 1px;
  border-color: var(--arm-light-grey);
  min-height: 79px;
  padding: 1rem;
}

html[theme="dark"] .dev-c-tab-heading {
  background-color: var(--arm-color-surface);
  border-color: var(--black);
}

@media (min-width: 992px) {
  .dev-c-tab-heading {
    border: none;
    min-height: unset;
    padding: 0;
  }

  html[theme="dark"] .dev-c-tab-heading {
    background-color: transparent;
  }
}

/* Facets */

.dev-c-show-more-less {
  --ads-button-background-color-tertiary: transparent;
  --ads-button-background-color-tertiary-focus: transparent;
  --ads-button-background-color-tertiary-hover: transparent;
  --ads-button-border-color-tertiary: transparent;
  --ads-button-border-color-tertiary-focus: transparent;
  --ads-button-border-color-tertiary-hover: transparent;
  --ads-button-color-tertiary: var(--arm-web-safe-blue);
  --ads-button-color-tertiary-focus: var(--arm-web-safe-orange);
  --ads-button-color-tertiary-hover: var(--arm-web-safe-orange);
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

html[theme="dark"] .dev-c-show-more-less {
  --ads-button-color-tertiary: var(--arm-light-blue);
  --ads-button-color-tertiary-focus: var(--arm-orange);
  --ads-button-color-tertiary-hover: var(--arm-orange);
}

/* Cards */

html[theme="dark"] .dev-c-card {
  --ads-card-border-color: var(--black);
}
.dev-c-card {
  --ads-card-border-color: var(--arm-light-grey);
  --ads-card-border-color-hover: var(--arm-web-safe-orange);
}

.dev-c-card__title {
  color: var(--arm-web-safe-blue);
  transition: color 0.3s ease-in-out;
}

.dev-c-card:focus-within .dev-c-card__title,
.dev-c-card:hover .dev-c-card__title {
  color: var(--arm-web-safe-orange);
}

html[theme="dark"] .dev-c-card .dev-c-card__title {
  color: var(--arm-light-blue);
}

html[theme="dark"] .dev-c-card:focus .dev-c-card__title,
html[theme="dark"] .dev-c-card:hover .dev-c-card__title {
  color: var(--arm-orange);
}

/* Filter Cards */

.dev-c-filter-card {
  --ads-card-border-color: var(--arm-light-grey);
  --ads-card-border-color-hover: var(--arm-web-safe-orange);
  --ads-card-border-width: 1px;
}

html[theme="dark"] .dev-c-filter-card {
  --ads-card-background-color: var(--arm-color-base);
  --ads-card-background-color-hover: var(--arm-color-base);
  --ads-card-border-color: var(--black);
  --ads-card-border-color-hover: var(--arm-orange);
}

.dev-c-filter-card ads-card-content {
  position: relative;
}

.dev-c-filter-card ads-card-content::before {
  background-color: var(--arm-web-safe-blue);
  content: "";
  height: calc(100% + 2px);
  left: -1px;
  position: absolute;
  top: -1px;
  transition: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  width: 12px;
}

.dev-c-filter-card:focus-within ads-card-content::before,
.dev-c-filter-card:hover ads-card-content::before {
  background-color: var(--arm-web-safe-orange);
}

html[theme="dark"] .dev-c-filter-card ads-card-content::before {
  background-color: var(--arm-blue);
}

html[theme="dark"] .dev-c-filter-card:focus-within ads-card-content::before,
html[theme="dark"] .dev-c-filter-card:hover ads-card-content::before {
  background-color: var(--arm-orange);
}

.dev-c-filter-card__content {
  transform: translateX(0);
  transition: transform 0.25s ease-in;
}

.dev-c-filter-card:focus-within .dev-c-filter-card__content,
.dev-c-filter-card:hover .dev-c-filter-card__content {
  transform: translateX(3px);
}

.dev-c-filter-card__subtitle {
  display: block;
  font-size: 12px;
  line-height: 1em;
  margin-bottom: 0.5rem;
}

.dev-c-filter-card__title {
  font-size: 18px;
  line-height: 1.1em;
  margin-bottom: 0.5rem;
}

@media (min-width: 768px) {
  .dev-c-filter-card__title {
    font-size: 20px;
  }
}

.dev-c-product-image {
  max-width: 325px;
}

.dev-c-filter-card__description {
  font-size: 14px;
}

.dev-c-filter-backdrop {
  background-color: #000000bf;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 4;
}

.dev-c-filter-button {
  --ads-button-font-size-large: 24px;
  height: 44px;
  width: 64px;
}

.dev-c-trending-now {
  --ads-card-background-color: var(--arm-color-overlay-light);
  --ads-card-background-color-hover: var(--arm-color-overlay-light);
  --ads-card-border-color: var(--arm-dark-grey);
  --ads-card-border-color-hover: var(--arm-dark-grey);
  --ads-card-border-width: 1px;
}

.dev-c-trending-now p {
  color: var(--white);
}

.dev-c-trending-now ads-card {
  max-width: 450px;
  width: 100%;
}

@media (min-width: 1200px) {
  html[dir="rtl"] .dev-c-trending-now {
    border-right: 1px solid var(--arm-dark-grey);
  }

  html:not([dir="rtl"]) .dev-c-trending-now {
    border-left: 1px solid var(--arm-dark-grey);
  }
}

/* Additional Resources Card*/
.dev-c-card--additional-resources {
  --ads-card-background-color: #f7f7f7;
  --ads-card-background-color-hover: #f7f7f7;
  --ads-card-border-color: #7d868c;
  --ads-card-border-color-hover: #7d868c;
  --ads-card-border-radius: 10px;
  --ads-card-border-width: 1px;
}

html[theme="dark"] .dev-c-card--additional-resources {
  --ads-card-background-color: #2d2f34;
  --ads-card-background-color-hover: #2d2f34;
  --ads-card-border-color: #2d2f34;
  --ads-card-border-color-hover: #2d2f34;
}

.dev-c-key-additional-resources__list {
  column-gap: 1rem;
  columns: 2;
}

/* Link Grid */
@media screen and (min-width: 576px) {
  .dev-c-link-grid {
    column-gap: 4rem;
    columns: 2;
    max-width: 800px;
  }

  .dev-c-link-grid > li {
    break-inside: avoid; /* For standard browsers like IE. :-) */
    page-break-inside: avoid; /* For Firefox. */
    -webkit-column-break-inside: avoid; /* For Chrome & friends. */
  }
}

/* Tags */

.dev-c-card-tag {
  flex-shrink: 0;
}

html:not([theme="dark"]) .dev-c-card-tag {
  --ads-tag-background-color: transparent;
  --ads-tag-border-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-focus: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-hover: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color-focus: var(--white, #fff);
  --ads-tag-color-hover: var(--white, #fff);
}

/* Article + Feature + Event List Cards */

.dev-c-article-card ads-card-content > *,
.dev-c-event-list-card ads-card-content > *,
.dev-c-feature-card ads-card-content > * {
  transition: color 0.3s ease-in-out;
}

.dev-c-article-card,
.dev-c-feature-card {
  --ads-card-background-color: var(--white, #fff);
  --ads-card-background-color-hover: var(--white, #fff);
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-hover: var(--arm-web-safe-orange, #ff6b00);
  --ads-card-border-radius: 10px;
  --ads-card-border-style: solid;
  --ads-card-border-width: 1px;
  --ads-card-box-shadow-hover: rgba(0, 0, 0, 0.16) 0 3px 6px 1px;
  margin: 0 auto;
  transition: color, border-color 0.3s ease-in-out;
}

html[theme="dark"] .dev-c-article-card,
html[theme="dark"] .dev-c-feature-card {
  --ads-card-background-color: var(--arm-color-surface, #27292d);
  --ads-card-background-color-hover: var(--arm-color-surface, #27292d);
  --ads-card-border-color: #000;
  --ads-card-border-color-hover: var(--arm-orange, #ff6b00);
}

.dev-c-article-card ads-card-content > *,
.dev-c-feature-card ads-card-content > * {
  grid-template-rows: repeat(1, 1fr);
}

.dev-c-article-card ads-card-content .dev-c-card-tag > *,
.dev-c-feature-card ads-card-content .dev-c-card-tag > * {
  pointer-events: none;
}

.dev-c-article-card ads-card-content time,
.dev-c-feature-card ads-card-content time {
  color: var(--arm-dark-grey, #7d868c);
  font-size: 14px;
}

.dev-c-article-card ads-card-content .dev-c-categories,
.dev-c-feature-card ads-card-content .dev-c-categories {
  border-color: var(--arm-black, #333e48);
  color: var(--arm-black, #333e48);
  cursor: pointer;
  padding-top: 0.5rem;
}

.dev-c-article-card ads-card-image,
.dev-c-feature-card ads-card-image {
  padding: 1rem 1.5rem 0;
}

html[theme="dark"] .dev-c-article-card,
html[theme="dark"] .dev-c-feature-card {
  --ads-card-border-color: #000;
  --ads-card-border-color-hover: var(--arm-orange, #ff6b00);
}

html[theme="dark"] .dev-c-article-card ads-card-content time,
html[theme="dark"] .dev-c-feature-card ads-card-content time {
  color: var(--arm-dark-grey, #7d868c);
}

.dev-c-feature-card ads-card-content .dev-c-card-tag {
  --ads-tag-background-color: var(--white, #fff);
}

html[theme="dark"] .dev-c-feature-card ads-card-content .dev-c-card-tag {
  --ads-tag-background-color: var(--arm-black, #333e48);
  --ads-tag-background-color-focus: var(--arm-black, #333e48);
  --ads-tag-background-color-hover: var(--arm-black, #333e48);
}

.dev-c-article-card ads-card-content {
  --ads-card-content-padding: 1.5rem;
}

.dev-c-article-card ads-card-content .dev-c-card-tag {
  position: initial;
  --ads-tag-justify-content: left;
  max-width: 100%;
}

.dev-c-article-card .dev-c-card__title {
  font-size: 20px;
}

html[theme="dark"] .dev-c-article-card {
  --ads-card-background-color: var(--arm-color-base, #1f2023);
  --ads-card-background-color-hover: var(--arm-color-base, #1f2023);
}

/* Event Cards */

.dev-c-event-list-card {
  --ads-card-background-color: var(--arm-light-grey, #e5eceb);
  --ads-card-background-color-hover: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-active: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-focus: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-hover: var(--arm-light-grey, #e5eceb);
  --ads-card-border-style: solid;
  --ads-card-border-width: 1px;
  --ads-card-border-radius: 10px;
  --ads-card-content-font-size: 14px;
  --ads-heading-color: #11809f;
  --ads-heading-font-size-secondary-large: 14px;
  --ads-heading-font-size-secondary-small: 14px;
  order: 5;
  transition: color, border-color 0.3s ease-in-out;
}

.dev-c-event-list-card .dev-c-event-list__events {
  grid-auto-flow: row;
}

.dev-c-event-list-card .dev-c-event-list__events > .dev-c-event {
  grid-auto-rows: 1fr 1fr 1fr;
}

.dev-c-event-list-card ads-card-content {
  --ads-card-content-padding: 0;
}

.dev-c-event-list-card h3 {
  font-size: 14px;
  font-weight: bold;
}

.dev-c-event-list-card ads-card-content .dev-c-event__title {
  vertical-align: middle;
}

.dev-c-event-list-card ads-card-content .dev-c-event__title a:hover {
  border: none;
}

.dev-c-event-list-card ads-card-content .dev-c-event__title h2 {
  display: inline-block;
  margin: 0;
}

.dev-c-event-list-card ads-card-content .dev-c-event-category {
  --ads-font-size-large: 14px;
  --ads-font-size-small: 14px;
  color: var(--arm-black, #333e48);
  font-size: 14px;
  margin: 0;
}

@media (min-width: 1200px) {
  .dev-c-event-list-card {
    order: initial;
  }
}

html[theme="dark"] .dev-c-event-list-card {
  --ads-card-background-color: var(--arm-color-surface, #27292d);
  --ads-card-background-color-hover: var(--arm-color-surface, #27292d);
  --ads-card-border-color: var(--black, #000);
  --ads-card-border-color-hover: none;
  --ads-heading-color: var(--arm-light-blue, #00c1de);
}

html[theme="dark"]
  .dev-c-event-list-card
  ads-card-content
  .dev-c-event-category {
  color: #a3a8ae;
}

/* Feature Card */

.dev-c-feature-card.dev-c-feature-card--main {
  grid-column: 0;
}

@media (min-width: 1200px) {
  .dev-c-feature-card.dev-c-feature-card--main {
    grid-column: 1/3;
  }
}

.dev-c-feature-card ads-card-content {
  --ads-card-content-padding: 1rem 1.5rem;
}

.dev-c-feature-card .dev-c-card__title {
  font-size: 24px;
}

.dev-c-feature-card ads-card-content > .dev-c-card-tag {
  cursor: pointer;
  left: 2rem;
  position: absolute;
  top: -3.5rem;
}

.dev-c-feature-card ads-card-content .dev-c-categories {
  font-size: 14px;
}

html[theme="dark"] .dev-c-feature-card ads-card-content .dev-c-categories {
  color: rgba(255, 255, 255, 0.9);
}

.dev-c-feature-card-small--noimage ads-card-content > .dev-c-card-tag {
  position: relative;
  top: 0;
  left: 0;
}
.dev-c-feature-card-small--noimage {
  height: 200px;
  overflow: hidden;
}
/* Promotional banner */

.dev-c-hero {
  position: relative;
}

.dev-c-hero__background {
  background-position: center center;
  background-size: cover;
  min-height: inherit;
  width: 100%;
}

.dev-c-hero__content {
  position: relative;
}

@media (min-width: 768px) {
  .dev-c-hero__background {
    min-height: inherit;
    position: absolute;
  }

  .dev-c-hero__content {
    z-index: 0;
  }
}

@media (min-width: 1200px) {
  .dev-c-hero {
    min-height: 554px;
  }
}

.kampyle_button-text {
  width: 100%;
}

/*
  Prevent c-col selector padding (from CoveoHeadless component) overriding ADS defaults when imported
*/
.c-col {
  padding-left: 16px;
  padding-right: 16px;
}

.dev-c-cta-link {
  --ads-cta-link-font-size: 16px;
}

.dev-c-feature-card-no-border {
  --ads-card-border-width: 0px;
  --ads-card-box-shadow-hover: none;
}
html:not([theme="dark"]) .dev-c-feature-card-no-border .dev-c-card-tag {
  --ads-tag-background-color-hover: var(--white, #fff);
  --ads-tag-color-hover: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color-focus: var(--arm-dark-grey-alt, #5d6c7a);
}
html[theme="dark"] .dev-c-feature-card-no-border .dev-c-card-tag {
  --ads-tag-border-color-hover: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-focus: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color-focus: #a3a8ae;
  --ads-tag-color-hover: #a3a8ae;
}

@media (max-width: 768px) {
  .dev-c-tech-specification-table {
    width: 100%;
    overflow-x: scroll;
  }
  .c-cta-button.is-medium {
    white-space: break-spaces;
  }
}

#header {
  margin-top: 80px;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

@media (max-width: 1198px) {
  #header {
    margin-top: 57px;
  }
}

#homeHeader {
  margin-top: 80px;
}

@media (max-width: 1198px) {
  #homeHeader {
    margin-top: 60px;
  }
}
.hide-star-rating {
  display: none !important;
}
#innerLayer > div > form > div.css-id3qj0 > div {
  .css-1xc1cyo path {
    fill: #333e48;
  }

  .css-xrn97w path {
    fill: #ffffff;
    stroke-width: 2;
    stroke: #1f2023;
  }
}
#innerLayer > div > form > div > div.css-1fngaay > a {
  color: rgba(0, 0, 0, 0.7) !important;
}
.survey-container {
  padding-right: 20px;
}
